var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page1" }, [
    _vm.closeShow
      ? _c(
          "div",
          { staticClass: "info" },
          [
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 24 } }, [
                  _c(
                    "div",
                    { staticClass: "info_content" },
                    [
                      _c("el-col", { attrs: { span: 23 } }, [
                        _c("span", [
                          _vm._v(
                            "实际运营情况报表，按出场时间统计停车实际运营收费情况，会根据异常处理动态更新；本报表适用于运营人员了解车场实际运营情况以及帮助财务人员掌握车场财务情况。"
                          ),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 1 } }, [
                        _c("img", {
                          attrs: { src: require("./img/close.png"), alt: "" },
                          on: {
                            click: function ($event) {
                              _vm.closeShow = false
                            },
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "content bgFFF" }, [
      _c(
        "div",
        { staticClass: "searchWrapper" },
        [
          _c("div", { staticClass: "search_box_title" }, [
            _vm._v(_vm._s(_vm.$t("searchModule.Query_Table"))),
          ]),
          _c(
            "el-form",
            {
              ref: "formInline",
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                "status-icon": "",
                "label-position": "right",
                "label-width": "75px",
                model: _vm.formInline,
              },
            },
            [
              _c("div", { staticClass: "col_box" }, [
                _c(
                  "div",
                  { staticClass: "col_left" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("searchModule.Date_search") } },
                      [
                        _c("a-date-picker", {
                          ref: "datePicker",
                          attrs: {
                            YearShowHidden: _vm.selectkeys[3],
                            selectkeys: _vm.selectkeys,
                            previousDate: _vm.previousDate,
                            nextDate: _vm.nextDate,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("searchModule.region"),
                          prop: "areaId",
                          "label-width": "70px",
                        },
                      },
                      [
                        _c("a-cascader", {
                          ref: "cascader",
                          on: { change: _vm.parkClear },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("searchModule.Merchant_affiliation"),
                          "label-width": "90px",
                        },
                      },
                      [_c("a-operation-select", { ref: "operationSelect" })],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("searchModule.Road_or_Parking_Lot"),
                          "label-width": "75px",
                        },
                      },
                      [
                        _c("my-component", {
                          ref: "parkInput",
                          attrs: {
                            areaIds: _vm.formInline.streetId
                              ? _vm.formInline.streetId
                              : _vm.formInline.areaId,
                            slaveRelations: "0,1",
                          },
                          on: { valueChange: _vm.completeValue },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("searchModule.Type_of_parking_lot"),
                          "label-width": "90px",
                        },
                      },
                      [_c("a-park-type-select", { ref: "parkTypeSelect" })],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("searchModule.Commercial_status"),
                          prop: "commercialStatus",
                          "label-width": "90px",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              "collapse-tags": "",
                              multiple: "",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.formInline.commercialStatus,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formInline,
                                  "commercialStatus",
                                  $$v
                                )
                              },
                              expression: "formInline.commercialStatus",
                            },
                          },
                          _vm._l(_vm.options, function (item) {
                            return _c("el-option", {
                              key: item.code,
                              attrs: { label: item.desc, value: item.code },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col_right" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          icon: "el-icon-search",
                          loading: _vm.loading,
                        },
                        on: {
                          click: function ($event) {
                            _vm.pageNum = 1
                            _vm.searchData()
                          },
                        },
                      },
                      [_vm._v("查询 ")]
                    ),
                  ],
                  1
                ),
              ]),
              _vm.$route.meta.authority.button.export
                ? _c("div", { staticClass: "col_box_boder" })
                : _vm._e(),
              _c("div", { staticClass: "col_box h44" }, [
                _c("div", { staticClass: "col_left" }),
                _c(
                  "div",
                  { staticClass: "col_right mbd4" },
                  [
                    _vm.$route.meta.authority.button.export
                      ? _c("exportFile", {
                          attrs: { exportData: _vm.exportData },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "statusContent" }, [
        _c("div", { staticClass: "status_info" }, [
          _vm._m(0),
          _c("div", { staticClass: "status_word" }, [
            _c(
              "p",
              [
                _c("span", [_vm._v("订单金额合计")]),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content:
                        "按计费规则计算的应收金额，不含任何优惠和异常处理的值",
                      placement: "top-start",
                    },
                  },
                  [
                    _c("img", {
                      attrs: { src: require("./img/tip.png"), alt: "" },
                    }),
                  ]
                ),
              ],
              1
            ),
            _c("p", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("moneyHandling")(_vm.statusList.totalShouldPayMoney)
                  ) +
                  " "
              ),
              _c("span", [_vm._v("元")]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "status_info" }, [
          _vm._m(1),
          _c("div", { staticClass: "status_word" }, [
            _c(
              "p",
              [
                _c("span", [_vm._v("修改后订单金额合计")]),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content:
                        "按出场时间在统计日期的「停车记录查询」应收金额（含「订单修改查询」中处理完成修正应收金额）之和",
                      placement: "top-start",
                    },
                  },
                  [
                    _c("img", {
                      attrs: { src: require("./img/tip.png"), alt: "" },
                    }),
                  ]
                ),
              ],
              1
            ),
            _c("p", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("moneyHandling")(
                      _vm.statusList.totalModifyShouldPayMoney
                    )
                  ) +
                  " "
              ),
              _c("span", [_vm._v("元")]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "status_info" }, [
          _vm._m(2),
          _c("div", { staticClass: "status_word" }, [
            _c(
              "p",
              [
                _c("span", [_vm._v("应收合计")]),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "停车记录中应收金额之和",
                      placement: "top-start",
                    },
                  },
                  [
                    _c("img", {
                      attrs: { src: require("./img/tip.png"), alt: "" },
                    }),
                  ]
                ),
              ],
              1
            ),
            _c("p", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("moneyHandling")(
                      _vm.statusList.totalCurrentShouldPayMoney
                    )
                  ) +
                  " "
              ),
              _c("span", [_vm._v("元")]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "status_info" }, [
          _vm._m(3),
          _c("div", { staticClass: "status_word" }, [
            _c(
              "p",
              [
                _c("span", [_vm._v("实付合计")]),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content:
                        "按出场时间在统计日期的「停车记录查询」实付金额之和",
                      placement: "top-start",
                    },
                  },
                  [
                    _c("img", {
                      attrs: { src: require("./img/tip.png"), alt: "" },
                    }),
                  ]
                ),
              ],
              1
            ),
            _c("p", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("moneyHandling")(_vm.statusList.totalActualPayMoney)
                  ) +
                  " "
              ),
              _c("span", [_vm._v("元")]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "status_info" }, [
          _vm._m(4),
          _c("div", { staticClass: "status_word" }, [
            _c(
              "p",
              [
                _c("span", [_vm._v("优惠合计")]),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content:
                        "按出场时间在统计日期的「停车记录查询」优惠总计之和",
                      placement: "top-start",
                    },
                  },
                  [
                    _c("img", {
                      attrs: { src: require("./img/tip.png"), alt: "" },
                    }),
                  ]
                ),
              ],
              1
            ),
            _c("p", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("moneyHandling")(_vm.statusList.totalDiscountMoney)
                  ) +
                  " "
              ),
              _c("span", [_vm._v("元")]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "status_info" }, [
          _vm._m(5),
          _c("div", { staticClass: "status_word" }, [
            _c(
              "p",
              [
                _c("span", [_vm._v("退实付合计")]),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content:
                        "按出场时间在统计日期的「停车记录查询」退实付金额之和",
                      placement: "top-start",
                    },
                  },
                  [
                    _c("img", {
                      attrs: { src: require("./img/tip.png"), alt: "" },
                    }),
                  ]
                ),
              ],
              1
            ),
            _c("p", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("moneyHandling")(
                      _vm.statusList.totalRefundActualPayMoney
                    )
                  ) +
                  " "
              ),
              _c("span", [_vm._v("元")]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "status_info" }, [
          _vm._m(6),
          _c("div", { staticClass: "status_word" }, [
            _c(
              "p",
              [
                _c("span", [_vm._v("退优惠合计")]),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content:
                        "按出场时间在统计日期的「停车记录查询」退优惠金额之和",
                      placement: "top-start",
                    },
                  },
                  [
                    _c("img", {
                      attrs: { src: require("./img/tip.png"), alt: "" },
                    }),
                  ]
                ),
              ],
              1
            ),
            _c("p", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("moneyHandling")(_vm.statusList.totalRefundAgioMoney)
                  ) +
                  " "
              ),
              _c("span", [_vm._v("元")]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "status_info switchType" }, [
          _c("p", { on: { click: _vm.switchClick } }, [
            _c("span", { class: _vm.switchNum != 0 ? "blueWord" : "" }, [
              _vm._v(_vm._s(_vm.switchNum == 0 ? "展开" : "收起") + " "),
            ]),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.switchNum == 0,
                  expression: "switchNum == 0",
                },
              ],
              attrs: { src: require("./img/fold.png"), alt: "" },
            }),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.switchNum == 1,
                  expression: "switchNum == 1",
                },
              ],
              attrs: { src: require("./img/hidden.png"), alt: "" },
            }),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.switchNum != 0,
              expression: "switchNum != 0",
            },
          ],
          staticClass: "amountContents",
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 5 } },
                    [
                      _c("el-col", { attrs: { span: 2 } }, [
                        _c("div", { staticClass: "amount_left" }, [
                          _c("p", [_vm._v("路内")]),
                        ]),
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-row",
                            {
                              staticStyle: { "margin-bottom": "5px" },
                              attrs: { gutter: 5 },
                            },
                            [
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c("div", { staticClass: "amount_box" }, [
                                  _c("p", [_vm._v("修改后订单金额合计")]),
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("moneyHandling")(
                                            _vm.statusList
                                              .insideSubTotalModifyShouldPayMoney
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("span", [_vm._v("元")]),
                                  ]),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c("div", { staticClass: "amount_box" }, [
                                  _c(
                                    "p",
                                    [
                                      _c("span", [_vm._v("应收合计")]),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: "停车记录中应收金额之和",
                                            placement: "top-start",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("./img/tip.png"),
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("moneyHandling")(
                                            _vm.statusList
                                              .insideSubTotalShouldPayMoney
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("span", [_vm._v("元")]),
                                  ]),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c("div", { staticClass: "amount_box" }, [
                                  _c("p", [_vm._v("优惠合计")]),
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("moneyHandling")(
                                            _vm.statusList
                                              .insideSubTotalDiscountMoney
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("span", [_vm._v("元")]),
                                  ]),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c("div", { staticClass: "amount_box" }, [
                                  _c("p", [_vm._v("实付合计")]),
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("moneyHandling")(
                                            _vm.statusList
                                              .insideSubTotalActualPayMoney
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("span", [_vm._v("元")]),
                                  ]),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { attrs: { gutter: 5 } },
                            [
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c("div", { staticClass: "amount_box" }, [
                                  _c("p", [_vm._v("退实付合计")]),
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("moneyHandling")(
                                            _vm.statusList
                                              .insideSubTotalRefundActualPayMoney
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("span", [_vm._v("元")]),
                                  ]),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c("div", { staticClass: "amount_box" }, [
                                  _c(
                                    "p",
                                    [
                                      _c("span", [_vm._v("欠费合计")]),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content:
                                              "按出场时间在统计日期的「停车记录查询」欠费金额之和",
                                            placement: "top-start",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("./img/tip.png"),
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("moneyHandling")(
                                            _vm.statusList
                                              .insideSubTotalDebtMoney
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("span", [_vm._v("元")]),
                                  ]),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c("div", { staticClass: "amount_box" }, [
                                  _c(
                                    "p",
                                    [
                                      _c("span", [_vm._v("单泊位应收")]),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: "单泊位应收累加值",
                                            placement: "top-start",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("./img/tip.png"),
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("moneyHandling")(
                                            _vm.statusList
                                              .insideBerthAvgShouldPayMoney
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("span", [_vm._v("元")]),
                                  ]),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c("div", { staticClass: "amount_box" }, [
                                  _c(
                                    "p",
                                    [
                                      _c("span", [_vm._v("退优惠合计")]),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content:
                                              "按出场时间在统计日期的「停车记录查询」退优惠金额之和",
                                            placement: "top-start",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("./img/tip.png"),
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("moneyHandling")(
                                            _vm.statusList
                                              .insideSubTotalRefundAgioPayMoney
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("span", [_vm._v("元")]),
                                  ]),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 5 } },
                    [
                      _c("el-col", { attrs: { span: 2 } }, [
                        _c("div", { staticClass: "amount_left" }, [
                          _c("p", [_vm._v("路外")]),
                        ]),
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-row",
                            {
                              staticStyle: { "margin-bottom": "5px" },
                              attrs: { gutter: 5 },
                            },
                            [
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c("div", { staticClass: "amount_box" }, [
                                  _c("p", [_vm._v("修改后订单金额合计")]),
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("moneyHandling")(
                                            _vm.statusList
                                              .outsideSubTotalModifyShouldPayMoney
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("span", [_vm._v("元")]),
                                  ]),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c("div", { staticClass: "amount_box" }, [
                                  _c(
                                    "p",
                                    [
                                      _c("span", [_vm._v("应收合计")]),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: "停车记录中应收金额之和",
                                            placement: "top-start",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("./img/tip.png"),
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("moneyHandling")(
                                            _vm.statusList
                                              .outsideSubTotalShouldPayMoney
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("span", [_vm._v("元")]),
                                  ]),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c("div", { staticClass: "amount_box" }, [
                                  _c("p", [_vm._v("优惠合计")]),
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("moneyHandling")(
                                            _vm.statusList
                                              .outsideSubTotalDiscountMoney
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("span", [_vm._v("元")]),
                                  ]),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c("div", { staticClass: "amount_box" }, [
                                  _c("p", [_vm._v("实付合计")]),
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("moneyHandling")(
                                            _vm.statusList
                                              .outsideSubTotalActualPayMoney
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("span", [_vm._v("元")]),
                                  ]),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { attrs: { gutter: 5 } },
                            [
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c("div", { staticClass: "amount_box" }, [
                                  _c("p", [_vm._v("退实付合计")]),
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("moneyHandling")(
                                            _vm.statusList
                                              .outsideSubTotalRefundActualPayMoney
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("span", [_vm._v("元")]),
                                  ]),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c("div", { staticClass: "amount_box" }, [
                                  _c(
                                    "p",
                                    [
                                      _c("span", [_vm._v("欠费合计")]),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content:
                                              "按出场时间在统计日期的「停车记录查询」欠费金额之和",
                                            placement: "top-start",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("./img/tip.png"),
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("moneyHandling")(
                                            _vm.statusList
                                              .outsideSubTotalDebtMoney
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("span", [_vm._v("元")]),
                                  ]),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c("div", { staticClass: "amount_box" }, [
                                  _c(
                                    "p",
                                    [
                                      _c("span", [_vm._v("单泊位应收")]),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: "单泊位应收累加值",
                                            placement: "top-start",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("./img/tip.png"),
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("moneyHandling")(
                                            _vm.statusList
                                              .outsideBerthAvgShouldPayMoney
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("span", [_vm._v("元")]),
                                  ]),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c("div", { staticClass: "amount_box" }, [
                                  _c(
                                    "p",
                                    [
                                      _c("span", [_vm._v("退优惠合计")]),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content:
                                              "按出场时间在统计日期的「停车记录查询」退优惠金额之和",
                                            placement: "top-start",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("./img/tip.png"),
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("moneyHandling")(
                                            _vm.statusList
                                              .outsideSubTotalRefundAgioPayMoney
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("span", [_vm._v("元")]),
                                  ]),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tableWrapper paddingB20" },
        [
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("screen", {
                attrs: {
                  checkBoxGroup: _vm.colData,
                  checkedColumns: _vm.checkedColumns,
                  reportType: 2,
                },
                on: { selectChange: _vm.selectChange },
              }),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              key: _vm.reload,
              staticStyle: { width: "100%" },
              attrs: {
                "header-cell-class-name": "headerCallStyle",
                "cell-style": _vm.callStyle,
                data: _vm.tableData,
              },
            },
            [
              _vm.colData[0].isTrue ||
              _vm.colData[1].isTrue ||
              _vm.colData[2].isTrue
                ? _c(
                    "el-table-column",
                    {
                      attrs: {
                        label: "",
                        "class-name": "headerCellQ headerSize",
                      },
                    },
                    [
                      _vm.colData[0].isTrue
                        ? _c("el-table-column", {
                            attrs: {
                              align: "center",
                              prop: "reportDate",
                              label: _vm.$t("searchModule.date"),
                              "min-width": "150",
                            },
                          })
                        : _vm._e(),
                      _vm.colData[1].isTrue
                        ? _c("el-table-column", {
                            attrs: {
                              align: "center",
                              prop: "areaName",
                              "min-width": "150",
                              label: _vm.$t("searchModule.region"),
                            },
                          })
                        : _vm._e(),
                      _vm.colData[2].isTrue
                        ? _c("el-table-column", {
                            attrs: {
                              align: "center",
                              prop: "operationName",
                              label: _vm.$t(
                                "searchModule.Merchant_affiliation"
                              ),
                              "min-width": "150",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.colData[3].isTrue ||
              _vm.colData[4].isTrue ||
              _vm.colData[5].isTrue ||
              _vm.colData[6].isTrue
                ? _c(
                    "el-table-column",
                    {
                      attrs: {
                        align: "center",
                        "class-name": "headerSize headerCellQ  ",
                      },
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("span", [_vm._v("道路/车场")]),
                        _c("i", { staticClass: "iconfont icontishi" }),
                      ]),
                      _vm.colData[3].isTrue
                        ? _c("el-table-column", {
                            attrs: {
                              align: "center",
                              prop: "parkName",
                              label: _vm.$t("searchModule.Road_or_Parking_Lot"),
                              "min-width": "150",
                              "class-name": "",
                            },
                          })
                        : _vm._e(),
                      _vm.colData[4].isTrue
                        ? _c("el-table-column", {
                            attrs: {
                              align: "center",
                              prop: "berthCount",
                              label: "泊位数",
                              "min-width": "150",
                            },
                          })
                        : _vm._e(),
                      _vm.colData[5].isTrue
                        ? _c("el-table-column", {
                            attrs: {
                              align: "center",
                              prop: "parkTypeName",
                              label: _vm.$t("searchModule.Type_of_parking_lot"),
                              "min-width": "150",
                            },
                          })
                        : _vm._e(),
                      _vm.colData[6].isTrue
                        ? _c("el-table-column", {
                            attrs: {
                              align: "center",
                              prop: "commercialStatusName",
                              label: _vm.$t("searchModule.Commercial_status"),
                              "min-width": "150",
                              "class-name": "",
                            },
                          })
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.colData[7].isTrue ||
              _vm.colData[8].isTrue ||
              _vm.colData[9].isTrue ||
              _vm.colData[10].isTrue ||
              _vm.colData[11].isTrue ||
              _vm.colData[12].isTrue ||
              _vm.colData[13].isTrue
                ? _c(
                    "el-table-column",
                    {
                      attrs: {
                        align: "center",
                        "class-name": "headerCellW headerSize",
                      },
                    },
                    [
                      _vm.colData[7].isTrue
                        ? _c("el-table-column", {
                            attrs: {
                              align: "center",
                              prop: "shouldPayMoney",
                              label: "订单金额（元）",
                              "min-width": "150",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return _c("div", {}, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("moneyHandling")(
                                              scope.row.shouldPayMoney
                                            )
                                          )
                                        ),
                                      ]),
                                    ])
                                  },
                                },
                              ],
                              null,
                              false,
                              2883068017
                            ),
                          })
                        : _vm._e(),
                      _vm.colData[8].isTrue
                        ? _c(
                            "el-table-column",
                            {
                              attrs: {
                                align: "center",
                                "min-width": "150",
                                prop: "exceptMoney",
                                label: "异常金额（元）",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return _c("div", {}, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("moneyHandling")(
                                                scope.row.exceptMoney
                                              )
                                            )
                                          ),
                                        ]),
                                      ])
                                    },
                                  },
                                ],
                                null,
                                false,
                                3659087823
                              ),
                            },
                            [
                              _c(
                                "template",
                                { slot: "header" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "vertical-align": "middle",
                                      },
                                    },
                                    [_vm._v("异常金额（元）")]
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content:
                                          "按出场时间统计的停车记录，出场当日异常处理中修改后订单金额和订单金额的差值",
                                        placement: "top-start",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          "vertical-align": "middle",
                                        },
                                        attrs: {
                                          src: require("./img/tip.png"),
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.colData[9].isTrue
                        ? _c("el-table-column", {
                            attrs: {
                              align: "center",
                              prop: "modifyShouldPayMoney",
                              label: "修改后订单金额（元）",
                              "min-width": "180",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return _c("div", {}, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("moneyHandling")(
                                              scope.row.modifyShouldPayMoney
                                            )
                                          )
                                        ),
                                      ]),
                                    ])
                                  },
                                },
                              ],
                              null,
                              false,
                              1831010913
                            ),
                          })
                        : _vm._e(),
                      _vm.colData[10].isTrue
                        ? _c("el-table-column", {
                            attrs: {
                              align: "center",
                              prop: "parkCardMoney",
                              label: "停车卡折扣（元）",
                              "min-width": "150",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return _c("div", {}, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("moneyHandling")(
                                              scope.row.parkCardMoney
                                            )
                                          )
                                        ),
                                      ]),
                                    ])
                                  },
                                },
                              ],
                              null,
                              false,
                              3991584972
                            ),
                          })
                        : _vm._e(),
                      _vm.colData[11].isTrue
                        ? _c("el-table-column", {
                            attrs: {
                              align: "center",
                              prop: "discountMoney",
                              label: "车场折扣（元）",
                              "min-width": "150",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return _c("div", {}, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("moneyHandling")(
                                              scope.row.discountMoney
                                            )
                                          )
                                        ),
                                      ]),
                                    ])
                                  },
                                },
                              ],
                              null,
                              false,
                              412577933
                            ),
                          })
                        : _vm._e(),
                      _vm.colData[12].isTrue
                        ? _c("el-table-column", {
                            attrs: {
                              align: "center",
                              prop: "actualShouldPayMoney",
                              label: "应收金额（元）",
                              "min-width": "150",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return _c("div", {}, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("moneyHandling")(
                                              scope.row.actualShouldPayMoney
                                            )
                                          )
                                        ),
                                      ]),
                                    ])
                                  },
                                },
                              ],
                              null,
                              false,
                              783424607
                            ),
                          })
                        : _vm._e(),
                      _vm.colData[13].isTrue
                        ? _c("el-table-column", {
                            attrs: {
                              align: "center",
                              "min-width": "150",
                              prop: "singleBerthShouldPayMoney",
                              label: "单泊位应收（元）",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return _c("div", {}, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("moneyHandling")(
                                              scope.row
                                                .singleBerthShouldPayMoney
                                            )
                                          )
                                        ),
                                      ]),
                                    ])
                                  },
                                },
                              ],
                              null,
                              false,
                              2609371010
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.colData[14].isTrue || _vm.colData[15].isTrue
                ? _c(
                    "el-table-column",
                    {
                      attrs: {
                        align: "center",
                        "class-name": "headerCellE headerSize headerCellQ  ",
                        label: "实收金额",
                        "min-width": "150",
                      },
                    },
                    [
                      _vm.colData[14].isTrue
                        ? _c("el-table-column", {
                            attrs: {
                              align: "center",
                              prop: "agioMoney",
                              "min-width": "150",
                              label: "优惠券金额（元）",
                              "class-name": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return _c("div", {}, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("moneyHandling")(
                                              scope.row.agioMoney
                                            )
                                          )
                                        ),
                                      ]),
                                    ])
                                  },
                                },
                              ],
                              null,
                              false,
                              2748117904
                            ),
                          })
                        : _vm._e(),
                      _vm.colData[15].isTrue
                        ? _c("el-table-column", {
                            attrs: {
                              align: "center",
                              prop: "actualPayMoney",
                              "min-width": "150",
                              label: "实付（元）",
                              "class-name": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return _c("div", {}, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("moneyHandling")(
                                              scope.row.actualPayMoney
                                            )
                                          )
                                        ),
                                      ]),
                                    ])
                                  },
                                },
                              ],
                              null,
                              false,
                              1617645750
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.colData[16].isTrue
                ? _c(
                    "el-table-column",
                    {
                      attrs: {
                        align: "center",
                        "class-name": "headerCellR headerSize",
                      },
                    },
                    [
                      _vm.colData[16].isTrue
                        ? _c("el-table-column", {
                            attrs: {
                              align: "center",
                              prop: "debtMoney",
                              label: "欠费（元）",
                              "min-width": "150",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return _c("div", {}, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("moneyHandling")(
                                              scope.row.debtMoney
                                            )
                                          )
                                        ),
                                      ]),
                                    ])
                                  },
                                },
                              ],
                              null,
                              false,
                              3273054663
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.colData[17].isTrue || _vm.colData[18].isTrue
                ? _c(
                    "el-table-column",
                    {
                      attrs: {
                        align: "center",
                        "class-name": "headerCellT headerSize headerCellQ",
                        label: "退款金额",
                        "min-width": "150",
                      },
                    },
                    [
                      _vm.colData[17].isTrue
                        ? _c("el-table-column", {
                            attrs: {
                              align: "center",
                              prop: "refundAgioMoney",
                              "min-width": "150",
                              label: "退优惠（元）",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return _c("div", {}, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("moneyHandling")(
                                              scope.row.refundAgioMoney
                                            )
                                          )
                                        ),
                                      ]),
                                    ])
                                  },
                                },
                              ],
                              null,
                              false,
                              3133709502
                            ),
                          })
                        : _vm._e(),
                      _vm.colData[18].isTrue
                        ? _c("el-table-column", {
                            attrs: {
                              align: "center",
                              "min-width": "150",
                              prop: "refundActualPayMoney",
                              label: "退实付（元）",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return _c("div", {}, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("moneyHandling")(
                                              scope.row.refundActualPayMoney
                                            )
                                          )
                                        ),
                                      ]),
                                    ])
                                  },
                                },
                              ],
                              null,
                              false,
                              3178297752
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagerWrapper bgFFF" },
            [
              _vm.total != 0
                ? _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.pageNum,
                      "page-size": _vm.pageSize,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.total,
                    },
                    on: { "current-change": _vm.handleCurrentChange },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "status_img" }, [
      _c("img", { attrs: { src: require("./img/urlQ.png"), alt: "" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "status_img" }, [
      _c("img", { attrs: { src: require("./img/urlX.png"), alt: "" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "status_img" }, [
      _c("img", { attrs: { src: require("./img/urlY.png"), alt: "" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "status_img" }, [
      _c("img", { attrs: { src: require("./img/urlE.png"), alt: "" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "status_img" }, [
      _c("img", { attrs: { src: require("./img/urlR.png"), alt: "" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "status_img" }, [
      _c("img", { attrs: { src: require("./img/urlW.png"), alt: "" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "status_img" }, [
      _c("img", { attrs: { src: require("./img/urlW.png"), alt: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }